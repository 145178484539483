<template>
  <div>
    <title-nav :title="'404 Error Page'" :nav="'404 Error Page'" />

    <section class="content">
      <div class="error-page">
        <h2 class="headline text-warning">404</h2>

        <div class="error-content">
          <h3><i class="fas fa-exclamation-triangle text-warning"></i> Oops! Page not found.</h3>
        </div>
        <!-- /.error-content -->
      </div>
      <!-- /.error-page -->
    </section>
  </div>
</template>
<script>
export default {
  mounted() {
    this.$root.$emit('changeBodyClass', {
      remove: 'login-page',
      add: 'sidebar-mini'
    })
  }
}
</script>
